import "./Home.css";
import "bootstrap/dist/css/bootstrap.css";
import Scanner from "../components/Scanner";
import * as React from "react";

function App() {
  return (
    <div className="App">
      <div className="App-header">Web Worker Scanner</div>
      <main>
        <Scanner></Scanner>
      </main>
    </div>
  );
}

export default App;
